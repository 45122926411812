import React, { useState } from "react";

export default function GalleryPostModal({ post, modalId, postClicked }) {
  // ===== STATE =====
  const [show, setShow] = useState([false]);
  // ===== END STATE =====

  // ===== EVENT HANDLERS =====
  const handleModalClick = e => {
    if (e.target.id === `Portfolio-modal`) {
      setShow(!show);
      document.getElementById(modalId).style.display = `none`;
      document.getElementById(modalId).classList.remove(`show`);
    }
  };
  const handleCloseBtnClick = e => {
    setShow(!show);
    document.getElementById(modalId).style.display = `none`;
    document.getElementById(modalId).classList.remove(`show`);
  };
  // ===== END EVENT HANDLERS =====

  return (
    <div
      className={`modal fade`}
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      style={{ display: postClicked ? 'block' : `none` }}
      aria-modal={postClicked}
      aria-hidden={!postClicked}
      onClick={e => handleModalClick(e)}
    >
      <div className="modal-dialog modal-dialog-scrollable shadow-lg" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close mb-3"
              data-dismiss="modal"
              onClick={e => handleCloseBtnClick(e)}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <h1 className="text-center">{post.title}</h1>
            <img
              src={`${post.image ? post.image.fluid.src : ``}`}
              className="imagepreview"
              style={{ width: `100%` }}
              alt={""}
            />
            <p id="modal_desc" className="m-0 p-3 bg-light text-dark">
              {post.description ? post.description.description : ``}
            </p>
            <div id="modal_links" className="text-center">
              {post.demoLink ? (
                <a
                  className="btn btn-dark p-3 m-3 align-self-center align-self-md-start text-light"
                  id="card_demo"
                  href={post.demoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DEMO
                </a>
              ) : (
                ``
              )}
              {post.codeLink ? (
                <a
                  className="btn btn-dark p-3 m-3 align-self-center align-self-md-start text-light"
                  id="card_code"
                  href={post.codeLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Code
                </a>
              ) : (
                ``
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
