import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Img from "gatsby-image"
import SEO from "../components/seo";

import Navigation from "../components/Navigation/Navigation";
import Main from "../components/Main/Main";
import Content from "../components/Content/Content";
import Title from "../components/Title/Title";
import Search from "../components/Search/Search";
import Advertisement from "../components/Advertisement/Advertisement";
import GalleryPostModal from "../components/Carousel/GalleryPostModal";
import Page from "../components/Page/Page";
import Footer from "../components/Footer/Footer";

const PortfolioPage = ({ data, isActive }) => {
  let categories = [
    ...new Set(data.portfolio.edges.map(({ node }) => node.category))
  ].reverse();
  let posts = [...data.portfolio.edges];
  let title = `Portfolio`;

  // ===== STATE =====
  const [post, setPost] = useState([null]);
  const [postClicked, setPostClicked] = useState([false]);

  // State
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [displayPosts, setDisplayPosts] = useState([]);

  const getSearchTerm = term => {
    setSearchTerm(term);
    
  };

  const getSelectedCategories = categories => {
    if (selectedCategories != categories) {
      setSelectedCategories(categories);
    }
    
  };

  const getDisplayPosts = () => {
    let filteredPosts = posts;
    if (selectedCategories.length > 0) {
      filteredPosts = filteredPosts.filter(({ node }) =>
        selectedCategories.includes(node.category)
      );
    }
  
    if (searchTerm != 0 && searchTerm != "") {
      filteredPosts = filteredPosts.filter(({ node }) =>
        node.title.toLowerCase().startsWith(searchTerm) ||
        node.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      );
    }
    return filteredPosts.sort((a, b) => a.node.title > b.node.title ? 1: -1);
  };

  // ===== END STATE =====
  useEffect(() => {
    setDisplayPosts(getDisplayPosts());
  }, [searchTerm, selectedCategories]);

  // ===== EVENT HANDLERS =====
  const handleClick = (portfolioPost, modalId) => {
    if (postClicked) {
      setPostClicked(true);
    }
    setPost(portfolioPost);
    document.getElementById(modalId).style.display = `block`;
    document.getElementById(modalId).classList.add(`show`);
  };
  // ===== END EVENT HANDLERS =====

  return (
    <>
      <SEO title={title} />
      <Layout>
        <Navigation />
        <Main>
          <Content>
            <Title title={title} />
            <Page>
              <Advertisement />
              <div className="container d-flex flex-column">
                <Search
                  count={displayPosts.length}
                  categories={categories}
                  getSearchTerm={getSearchTerm}
                  getSelectedCategories={getSelectedCategories}
                />
              <ul id="Gallery" className="list-unstyled row m-auto">
                {displayPosts.map(({ node }, i) => (
                  <li key={i} className="m-2">
                    <Img 
                    className="imagepreview"
                    fluid={node.image.fluid} 
                    style={{
                      width: 260,
                      height: 220,
                    }} 
                    onClick={() => handleClick(node, `${title}-modal`)}/>
                    <h3 className=" mt-2 mb-3 text-center">{node.title}</h3>
                  </li>
                ))}
              </ul>
              <GalleryPostModal
                  modalId={`${title}-modal`}
                  post={post}
                  postClicked={postClicked}
              />
              </div>
              <Advertisement />
            </Page>
          </Content>
          <Footer />
        </Main>
      </Layout>
    </>
  )
};

export const query = graphql`
  {
    portfolio: allContentfulPortfolioPost {
      edges {
        node {
          title
          image {
            fluid(toFormat: PNG) {
              src
              ...GatsbyContentfulFluid
            }
          }
          description {
            description
          }
          demoLink
          codeLink
          category
        }
      }
    }
  }
`;

export default PortfolioPage;
